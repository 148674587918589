/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyB48cw2mxiB5wOFIft_F6CLGxghvZMcJHw",
  "appId": "1:899751837829:web:4f7cae26a4df99f0c4a9ec",
  "authDomain": "schooldog-i-colquitt-ga.firebaseapp.com",
  "measurementId": "G-2F4XWBDS1D",
  "messagingSenderId": "899751837829",
  "project": "schooldog-i-colquitt-ga",
  "projectId": "schooldog-i-colquitt-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-colquitt-ga.appspot.com"
}
